import React from 'react'
import { Avatar } from 'antd'
import { capitalizeFirstLetter, generateColor } from 'Utils'
import './index.scss'
import PieChart from 'Components/Charts/PieChart'
import BarChart from 'Components/Charts/BarCharts'

// const filteredInfo = pickBy(userProfileSchema, identity)

interface userInfo {
  [key: string]: string | number | null
}

interface StyleAndFunctionalInterface {
  data: string
}

const StyleAndFunctional = ({ data }: StyleAndFunctionalInterface) => {
  return (
    <div onClick={(e) => e.stopPropagation()} className="StyleAndFunctionalComponent">
      {/* <PieChart hideLegends={false} data={data} isVertical={true} /> */}
      <BarChart data={data} type="horizontalBar" isStacked={false} chartId={false} />
    </div>
  )
}

export default StyleAndFunctional
