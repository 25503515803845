import React from 'react'
import { TextBlock, RectShape } from 'react-placeholder/lib/placeholders'
const ScreenPlaceholder = () => {
  return (
    <div className="ScreenPlaceHolder">
      <TextBlock
        color="#ffff"
        rows={1}
        style={{ width: '40%', fontSize: '3em', padding: '10px 0' }}
      />

      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(4, 1fr)',
          gridTemplateRows: 'repeat(2, 300px)',
          gridGap: 10,
        }}
      >
        <RectShape color="#ffff" style={{ gridColumn: 'span 1', gridRow: 'span 2' }} />
        <RectShape color="#ffff" style={{ gridColumn: 'span 1', gridRow: 'span 1' }} />
        <RectShape color="#ffff" style={{ gridColumn: 'span 1', gridRow: 'span 1' }} />
        <RectShape color="#ffff" style={{ gridColumn: 'span 1', gridRow: 'span 1' }} />
        <RectShape color="#ffff" style={{ gridColumn: 'span 1', gridRow: 'span 1' }} />
        <RectShape color="#ffff" style={{ gridColumn: 'span 1', gridRow: 'span 1' }} />
        <RectShape color="#ffff" style={{ gridColumn: 'span 1', gridRow: 'span 1' }} />
        <RectShape color="#ffff" style={{ gridColumn: 'span 1', gridRow: 'span 1' }} />
      </div>
    </div>
  )
}

export default ScreenPlaceholder
