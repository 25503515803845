import React from 'react'
import startCase from 'lodash/startCase'
import { Empty } from 'antd'
import './index.scss'
const keys = [
  ['pageview', 'Page View'],
  ['time_on_page', 'Time on Page (second)'],
  ['pageview_per_session', 'Page View per Session'],
  ['time_on_session', 'Time on Session (second)'],
  ['browsers', 'Browsers'],
  ['devices', 'Devices'],
]
const Interactions = ({ data }) => {
  if (!data) return <Empty description={false}></Empty>
  return (
    <div className="InteractionsComponent">
      {keys.map((key) => (
        <div className="row" key={key[0]}>
          <span className="title">{key[1]}:</span>
          <span className="value">{data[key[0]] || 'N/A'}</span>
        </div>
      ))}
    </div>
  )
}

export default Interactions
