import React from 'react'
import './index.scss'

const Segments = ({ data }) => {
  if (!data || !data.length) return 'No Data'

  return (
    <div className="SegmentsComponent mt-4" onClick={(e) => e.stopPropagation()}>
      {data.map((item, index) => (
        <div key={index} className="segment-item">
          {item}
        </div>
      ))}
    </div>
  )
}

export default Segments
