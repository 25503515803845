import React from 'react'
import { Avatar } from 'antd'
import { capitalizeFirstLetter, generateColor } from 'Utils'
import './index.scss'

// const filteredInfo = pickBy(userProfileSchema, identity)

interface userInfo {
  [key: string]: string | number | null;
}

interface UserProfileInterface {
  data: userInfo;
}

const UserProfile = ({ data }: UserProfileInterface) => {
  const _name = data?.name || 'Unknown'
  const _nameArr = (_name as string).split(' ')
  const length = _nameArr.length
  const sortName = length === 1 ? _nameArr[0][0] : _nameArr[0][0] + _nameArr[length - 1][0]
  const color = generateColor(_name)

  return (
    <div onClick={(e) => e.stopPropagation()} className="UserProfileComponent">
      <Avatar className="user-avatar" style={{ width: 50, height: 50, background: color }}>
        {sortName}
      </Avatar>
      {Object.keys(data).map((item: string) => (
        <div className="profile-item" key={item}>
          <div className="title" >{capitalizeFirstLetter(item)}:</div>
          <div className='content' >{data[item] || 'null'}</div>
        </div>
      ))}
    </div>
  )
}

export default UserProfile
