import React, { Component } from 'react'
import { AutoSizer } from 'react-virtualized'
import { UncontrolledReactSVGPanZoom, ReactSVGPanZoom } from 'react-svg-pan-zoom'
import { connect } from 'react-redux'
import cloneDeep from 'lodash/cloneDeep'
import classNames from 'classnames'
import get from 'lodash/get'
import { message } from 'antd'
import { CJ_LINE_WIDTH, CJ_NODE_WIDTH, CJ_NODE_HEIGHT, NODE_TYPES } from './constants'
import CJNode from 'Components/CJNode'
import SplitPath from './SplitPath'
import { flattenNodes } from 'Models/customer-journey'
import { roundPathCorners, generateLineCommand, getUUId, swapContentNode } from 'Utils'

import CustomTools from './CustomTools'
import './index.scss'
import LoadingIcon from 'Components/LoadingIcon'
import { colors } from 'DesignSystem'

const INIT_X = window.innerWidth / 2 - CJ_NODE_WIDTH / 2
const INIT_Y = CJ_NODE_HEIGHT + 50

class CustomerJourneyDesign extends Component {
  maxLevel = 0
  maxLeftCount = 0
  nodesByLevel = {}
  state = {
    vertical: true,
    loading: true,
    nodesTree: [],
    svgWidth: 500,
    svgHeight: 500,
    initialLoad: false,
    CJTree: this.props.CJTree,
  }

  Viewer = React.createRef()
  AutoSizer = React.createRef()

  componentDidMount() {
    this.draw({ isStart: true })
  }

  componentDidUpdate(prevProps, prevState) {
    // resize SVG every time new node added or deleted
    if (this.state.loading) {
      setTimeout(() => {
        this.resizeSVG()
      }, 0)
    }

    // render view on center of viewer on initial load only
    if (prevState.initialLoad != this.state.initialLoad) {
      // this.Viewer.fitSelection(
      //   -window.innerWidth / 2 + CJ_NODE_WIDTH / 2,
      //   -INIT_Y,
      //   this.state.svgWidth * 2,
      //   this.state.svgHeight
      // )
      // this.Viewer.setPointOnViewerCenter(100, 100, 1)
    }

    if (this.props.shouldDraw) {
      this.props.setShouldDraw(false)
      this.setState({ CJTree: this.props.CJTree }, () => {
        this.draw({ isStart: true, isAddNode: false })
      })
    }
  }

  resizeSVG = () => {
    let CJTree = document.querySelector('.CJViewer').querySelector('.CJTree')
    // Get the bounds of the SVG content
    const CJTreeSize = CJTree.getBoundingClientRect()

    if (CJTreeSize.width || CJTreeSize.height) {
      this.setState({
        svgWidth: CJTreeSize.width,
        svgHeight: CJTreeSize.height,
        initialLoad: true,
      })
    }
    // this.Viewer.fitSelection(0, 0, this.state.svgWidth, this.state.svgHeight)
  }

  draw = (options = {}) => {
    const { isStart, isAddNode } = options
    this.setState({ loading: true }, () => {
      this.computeNodeProperties(this.state.CJTree, { isStart, isAddNode })
      this.findLeftAndRightNode(this.state.CJTree)
      this.computeChildrenPosition(this.state.CJTree, this.state.CJTree.x)
      this.balanceCJTree(this.state.CJTree)
      this.setState({ loading: false })
    })
  }

  grabNodeHasMultiChildNode = (node, stack) => {
    stack.push(node)
    if (node.childrenNodes && node.childrenNodes.length === 1)
      return this.grabNodeHasMultiChildNode(node.childrenNodes[0], stack)
    if (node.childrenNodes.length === 2)
      return (node.childrenNodes[0].x + node.childrenNodes[1].x) / 2
  }

  balanceCJTree = (node) => {
    if (!node) return
    if (node.childrenNodes.length === 2) {
      node.x = (node.childrenNodes[0].x + node.childrenNodes[1].x) / 2
    } else if (node.childrenNodes.length === 1) {
      let stack = [node]
      const value = this.grabNodeHasMultiChildNode(node.childrenNodes[0], stack)
      if (value) stack.forEach((n) => (n.x = value))
    }
    node.x -= this.maxLeftCount * CJ_NODE_WIDTH

    node.childrenNodes.forEach((n, index) => {
      this.balanceCJTree(n)
    })
  }

  computeNodesTree = (node, type, value, alias) => {
    swapContentNode(node, { ...cloneDeep(NODE_TYPES[type]), value: value, alias: alias })
    this.draw({ isAddNode: true, isStart: true })
  }

  findLeftAndRightNode = () => {
    flattenNodes.length = 0
    Object.keys(this.nodesByLevel).forEach((key) => {
      this.nodesByLevel[key].forEach((node, index) => {
        flattenNodes.push(node)
        node.isMaxLevel = node.level === this.maxLevel
        node.leftNode = this.nodesByLevel[key][index - 1]
        node.rightNode = this.nodesByLevel[key][index + 1]
      })
    })
  }

  computeNodeProperties = (node, { isStart, isAddNode, level, parentNode, index, hasSibling }) => {
    if (!node || !node.childrenNodes) return
    node.nodeUuid = node.nodeUuid || getUUId()
    node.nodeAttribute = true
    node.path = node.path || ''
    if (isStart) {
      this.nodesByLevel = {}
      flattenNodes.length = 0
    }
    const isTwoNode = node.childrenNodes.length === 2
    level = level || 1
    this.maxLevel = level > this.maxLevel ? level : this.maxLevel

    node.leftChildNodesCount = 0
    node.rightChildNodesCount = 0

    if (isTwoNode) {
      node.leftChildNodesCount = 1
      node.rightChildNodesCount = 1
    }

    node.childrenNodes.forEach((n, i) => {
      n.leftNode = node.childrenNodes[i - 1]
      n.hasSibling = node.childrenNodes.length > 1
      n.priority = i
      n.path = node.path ? `${node.path}.childrenNodes[${i}]` : `childrenNodes[${i}]`

      const { leftChildNodesCount, rightChildNodesCount } = this.computeNodeProperties(n, {
        isStart: false,
        index: i,
        parentNode: node,
        level: level + 1,
        hasSibling: node.childrenNodes.length > 1,
      })
      // node.path = node.path + childPath
      node.leftChildNodesCount += leftChildNodesCount
      node.rightChildNodesCount += rightChildNodesCount
    })
    //set node props
    // node.path = path
    node.index = index
    node.parentNode = parentNode
    node.name = NODE_TYPES[node.nodeType].name
    node.icon = NODE_TYPES[node.nodeType].icon
    if (index === 0 && hasSibling) node.nodeAttribute = false
    if (parentNode) node.parent_node_uuid = parentNode.nodeUuid
    node.level = level

    this.maxLeftCount =
      node.leftChildNodesCount > this.maxLeftCount ? node.leftChildNodesCount : this.maxLeftCount
    //assign all node to flatten object, which key is level and values are same level nodes
    if (!this.nodesByLevel[level]) this.nodesByLevel[level] = []
    this.nodesByLevel[level].push(node)
    node.rowIndex = this.nodesByLevel[level].length - 1
    return node
  }

  resverseCompute = (node, impact) => {
    // compute left of parerntNode by impact index
    node.x += CJ_NODE_WIDTH * impact
    if (node.parentNode) this.resverseCompute(node.parentNode, impact)
  }

  computeChildrenPosition = (node, index, originalPos = { x: 0, y: 0 }) => {
    if (index === 0) node.position = originalPos
    // calculate x-axis depend on leftChildNodesCount property
    // which was computed by computeNodeProperties func
    let left = 0
    left += node.leftChildNodesCount * CJ_NODE_WIDTH
    // Depend on parent
    if (node.parentNode) {
      left = node.parentNode.x
      if (node.index === 0 && node.hasSibling) left -= CJ_NODE_WIDTH
      else if (node.index === 1) left += CJ_NODE_WIDTH
      // compute impact
      if (node.leftNode) {
        let impact = 0
        if (node.leftNode.x === left) impact = 2
        else if (node.leftNode.x + CJ_NODE_WIDTH === left) impact = 1
        left += CJ_NODE_WIDTH * impact
        if (impact) this.resverseCompute(node.parentNode, impact)
      }
    }

    let top = node.level * (CJ_NODE_HEIGHT + CJ_LINE_WIDTH)

    node.x = left
    node.y = top - 150

    node.childrenNodes.forEach((n, index) => {
      this.computeChildrenPosition(n)
    })
  }

  renderColor = (node) => {
    if (!['virtual', 'split'].includes(node.nodeType)) {
      return NODE_TYPES[node.nodeType].bgColor
    }
    return this.renderColor(node.parentNode)
  }

  deleteNode = (node) => {
    if (!node.path) message.error('Can not delete this Node!')
    let n = get(this.state.CJTree, node.path)
    swapContentNode(n, { ...cloneDeep(NODE_TYPES['virtual']) })
    this.props.setCJStates({ key: 'isCJModified', value: true })
    this.props.setShouldDraw(true)
  }

  renderNodes = (node, index) => {
    function findNodePosition(node, nodeUuid, index = { count: 0 }) {
      // If the current node is the one we're looking for, return its position
      if (node.nodeUuid === nodeUuid) {
        return index.count + 1
      }

      // If the current node is not "virtual", increase the count
      if (node.nodeType !== 'virtual') {
        index.count++
      }

      // Traverse the childrenNodes if they exist
      if (node.childrenNodes && node.childrenNodes.length > 0) {
        for (let i = 0; i < node.childrenNodes.length; i++) {
          const position = findNodePosition(node.childrenNodes[i], nodeUuid, index)
          if (position !== undefined) {
            return position
          }
        }
      }

      // Return undefined if the nodeUuid was not found in this branch
      return undefined
    }
    const { isEditing, isFetchingCJ } = this.props
    if (isFetchingCJ) return
    const nod = [
      <CJNode
        {...node}
        isEditing={isEditing}
        bgColor={this.renderColor(node)}
        description={NODE_TYPES[node.nodeType].description}
        nodesTree={this.state.CJTree}
        computeNodesTree={this.computeNodesTree}
        key={`${node.level}-${index || 0}-${node.nodeUuid}`}
        vertical={this.state.vertical}
        index={index}
        level={node.level}
        node={node}
        width={CJ_NODE_WIDTH}
        height={CJ_NODE_HEIGHT}
        changeTool={(tool) => this.Viewer && this.Viewer.changeTool(tool)}
        deleteNode={this.deleteNode}
        isRunning={this.props.isRunning}
      ></CJNode>,
    ]

    const childrenNodes = node.childrenNodes.map((n, i) => {
      // Todo: duplicate assign parent node in computeNodeProperties
      n.parentNode = node
      return this.renderNodes(n, i)
    })

    const lines = this.renderMultiPath(node)
    return (
      <g key={`${node.level}-${index || 0}`}>
        {lines}
        {nod.concat(childrenNodes)}
      </g>
    )
  }

  renderMultiPath(node) {
    if (!node.childrenNodes.length) return
    let childPos = node.childrenNodes.map((n) => ({ x: n.x, y: n.y }))
    let nodes = [{ x: node.x, y: node.y }].concat(childPos)
    nodes = nodes.map((n, i) => {
      let top = n.y
      if (i === 0) top = n.y + CJ_NODE_HEIGHT
      return { x: n.x + CJ_NODE_WIDTH / 2, y: top }
    })
    let cmd = generateLineCommand(nodes)
    const path = roundPathCorners(cmd, {
      exceptCommands: [1, 5],
    })
    const isSplitType = ['checkReachability', 'checkUserAttr', 'split', 'waitIn'].includes(
      node.nodeType
    )
    let yesNoPercentage = '50%'
    if (isSplitType) {
      let x1 = node?.childrenNodes?.[1]?.x - node.childrenNodes?.[0]?.x
      let x2 = node?.x - node.childrenNodes?.[0]?.x
      yesNoPercentage = (x2 / x1) * 100 + '%'
    }

    const color = this.renderColor(node)
    const leftValue = node.value?.left_branch >= 0
    const rightValue = node.value?.right_branch >= 0
    return (
      <SplitPath
        dash={isSplitType ? 5 : 0}
        x={node.x}
        y={node.y}
        // leftTitle={leftValue && `${leftValue}%`}
        // rightTitle={rightValue && `${rightValue}%`}
        leftTitle={leftValue ? 'A' : 'No'}
        rightTitle={rightValue ? 'B' : 'Yes'}
        nodeUuid={node.nodeUuid}
        bgColor={color}
        path={path}
        showText={isSplitType}
        percentage={yesNoPercentage}
        isSplitType={isSplitType}
        leftPathColor={(node.nodeType === 'split' && colors.systemColor.cyan_5) || color}
        rightPathColor={(node.nodeType === 'split' && colors.systemColor.blue_6) || color}
      ></SplitPath>
    )
  }

  render() {
    const { id, isFetchingCJ } = this.props
    return (
      <div className="CustomerDesgin">
        {isFetchingCJ && (
          <div className="cj-loading-overlay">
            <LoadingIcon style={{ left: 'unset', top: 'unset' }}></LoadingIcon>
          </div>
        )}
        <AutoSizer ref={this.AutoSizer}>
          {({ width, height }) =>
            width === 0 || height === 0 ? null : (
              <UncontrolledReactSVGPanZoom
                ref={(Viewer) => (this.Viewer = Viewer)}
                width={width}
                height={height}
                className="CJViewer"
                customToolbar={(props) => (
                  <CustomTools width={width} height={height} Viewer={this.Viewer} {...props} />
                )}
                scaleFactorMin={0.5}
                detectAutoPan={false}
                SVGBackground="none"
                preventPanOutside
                background="none"
                defaultTool="auto"
                setPointOnViewerCenter
                miniature
                customMiniature={() => null}
              >
                <svg width={this.state.svgWidth} height={this.state.svgHeight}>
                  <defs>
                    <pattern
                      id="myPattern"
                      width={CJ_NODE_WIDTH / 8}
                      height={CJ_NODE_WIDTH / 8}
                      patternUnits="userSpaceOnUse"
                      fill="white"
                    >
                      <circle cx={20} cy={10} r="1" fill="rgba(0,0,0,.2)" />
                    </pattern>
                  </defs>
                  <rect
                    x={-width * 3}
                    y={-height * 3}
                    width={width * 6}
                    height={height * 6}
                    // fill="url(#myPattern)"
                    fill="url(#F0F0F0)"
                  />
                  <g className="CJTree" transform={`translate(${INIT_X}, ${INIT_Y})`}>
                    {this.renderNodes(this.state.CJTree)}
                  </g>
                </svg>
              </UncontrolledReactSVGPanZoom>
            )
          }
        </AutoSizer>
      </div>
    )
  }
}

export default connect(
  (states) => ({
    CJTree: states.customerJourney.CJTree,
    flattenNodes: states.customerJourney.flattenNodes,
    shouldDraw: states.customerJourney.shouldDraw,
    isFetchingCJ: states.customerJourney.isFetchingCJ,
  }),
  (dispatch) => ({
    setCJStates: dispatch.customerJourney.setCJStates,
    updateNode: dispatch.customerJourney.updateNode,
    setShouldDraw: dispatch.customerJourney.setShouldDraw,
  })
)(CustomerJourneyDesign)
