import React from 'react'
import './index.scss'
import { Empty } from 'antd'
import moment from 'moment'
import CaretDownOutlined from '@ant-design/icons/CaretDownOutlined'
type Activity = {
  date_time: string
  activities: string
}

interface Activities {
  data: Activity[]
}

const ActivityItem = ({ activity }: any) => {
  let dateTime = moment(activity.date_time).format('L hh:mm a')

  return (
    <div className="ActivityItemComponent">
      <span className="timestamp column">{dateTime}</span>
      <span className="interactions column">{activity.activities}</span>
    </div>
  )
}

const Activities = ({ data }: Activities) => {
  if (!data || !data.length) return <Empty description={false}></Empty>
  return (
    <div onClick={(e) => e.stopPropagation()} className="ActivitiesComponent">
      <div className="activities-container">
        <div className="title-wrapper">
          <div className="title">
            Timestamps <CaretDownOutlined />
          </div>
          <div className="title interaction">Interactions</div>
        </div>

        <div className="items-wrapper">
          {data.map((item, index) => (
            <ActivityItem activity={item} key={index}></ActivityItem>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Activities
