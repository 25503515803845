import React from 'react'
import { capitalizeFirstLetter } from 'Utils'
import './index.scss'

interface values {
  [key: string]: string | number | null;
}

interface ValuesInterface {
  data: values;
}

const numberFormatter = (num: any) => {
  switch (true) {
    case num >= 1.0e9:
      return (num / 1.0e9).toFixed(1) + 'B'
    case num.toFixed() >= 1.0e6:
      return (num.toFixed(1) / 1.0e6).toFixed(1) + 'M'
    case num >= 1.0e3:
      return (num.toFixed(1) / 1.0e3).toFixed(1) + 'K'
    case num === 'null':
      return 'N/A'
    default:
      return num.toFixed()
  }
}

const Values = ({ data }: ValuesInterface) => {
  return (
    <div onClick={(e) => e.stopPropagation()} className="ValuesComponent">
      {Object.keys(data).map((item: string) => (
        <div className="value-item" key={item}>
          <div className="title" style={{ fontWeight: 700 }}>
            {capitalizeFirstLetter(item)}
          </div>
          <div className="value">
            {typeof data[item] === 'number' ? numberFormatter(data[item]) : data[item] || 'N/A'}
          </div>
        </div>
      ))}
    </div>
  )
}

export default Values
