import React from 'react'
import { UserCheck } from '@styled-icons/boxicons-regular/UserCheck'
import { User } from '@styled-icons/boxicons-regular/User'
import { Coupon } from '@styled-icons/remix-line/Coupon'
import { Time } from '@styled-icons/boxicons-regular/Time'
import { WebAsset } from '@styled-icons/material/WebAsset'
import { Textsms } from '@styled-icons/material-outlined/Textsms'
import { CurrentLocation } from '@styled-icons/boxicons-regular/CurrentLocation'
import { EmailOutline } from '@styled-icons/evaicons-outline/EmailOutline'
import { CallSplit } from '@styled-icons/material-rounded/CallSplit'
import { Enter } from '@styled-icons/icomoon/Enter'
import { Popup } from '@styled-icons/entypo/Popup'
import { Notification } from '@styled-icons/entypo/Notification'
import { NumberOutlined } from '@ant-design/icons'

import cloneDeep from 'lodash/cloneDeep'
import { data } from 'jquery'

const CJ_NODE_COLOR_PALETTE = {
  default: '#389E0D',
  conditions: '#D48806',
  channel: '#531DAB',
  audience: '#08979C',
  channels: '#D48806',
}

export const CJ_LINE_WIDTH = 60
export const CJ_NODE_WIDTH = 256
export const CJ_NODE_HEIGHT = 176
export const CJ_ROUND_NODE_HEIGHT = 41
export const CJ_NODE_MARGIN = 65
export const VIRTUAL_NODE = {
  nodeType: 'virtual',
  index: 0,
  name: 'Virtual',
  icon: <Enter />,
  maxChildren: 1,
  childrenNodes: [],
  nodeAttribute: true,
}

export const POPUP = {
  spingame: {
    nodeType: 'spingame',
    name: 'Spin Game',
    parentType: 'popup',
    description: 'Popup window to customers',
    bgColor: CJ_NODE_COLOR_PALETTE.channels,
    value: {},
  },
  randomvoucher: {
    nodeType: 'randomvoucher',
    name: 'Random Voucher',
    parentType: 'popup',
    description: 'Popup window to customers',
    bgColor: CJ_NODE_COLOR_PALETTE.channels,
    value: {},
  },
  codedefault: {
    nodeType: 'codedefault',
    name: 'Code Default',
    parentType: 'popup',
    description: 'Popup window to customers',
    bgColor: CJ_NODE_COLOR_PALETTE.channels,
    value: {},
  },
}

export const POPUPINIT = [
  {
    ...POPUP['spingame'],
    data: 'spingame',
  },
  {
    ...POPUP['randomvoucher'],
    data: 'randomvoucher',
  },
  {
    ...POPUP['codedefault'],
    data: 'codedefault',
  },
]

export const NODE_TYPES = {
  entrance: {
    nodeType: 'entrance',
    name: 'Entrance',
    icon: <Enter />,
    maxChildren: 1,
    nodeAttribute: true,
    childrenNodes: [{ ...VIRTUAL_NODE }],
    bgColor: CJ_NODE_COLOR_PALETTE.default,
    description: 'Destination to enable customer journey tree',
    // parentType: 'condition',
    value: {
      channel: null,
    },
    alias: 1,
  },
  virtual: VIRTUAL_NODE,
  wait: {
    nodeType: 'wait',
    parentType: 'wait',
    name: 'Wait',
    icon: <Time />,
    maxChildren: 1,
    nodeAttribute: true,
    bgColor: CJ_NODE_COLOR_PALETTE.conditions,
    childrenNodes: [cloneDeep(VIRTUAL_NODE)],
    description: 'Wait for a certain time to execute the next step.',
    value: {},
  },
  waitIn: {
    nodeType: 'waitIn',
    parentType: 'waitIn',
    name: 'Wait in',
    icon: <Time />,
    maxChildren: 2,
    nodeAttribute: true,
    bgColor: CJ_NODE_COLOR_PALETTE.conditions,
    childrenNodes: [
      { ...VIRTUAL_NODE, nodeAttribute: false },
      { ...VIRTUAL_NODE, index: 1, nodeAttribute: true },
    ],
    description: 'Wait for interaction',
    value: {},
  },
  promotion: {
    nodeType: 'promotion',
    parentType: 'promotion',
    name: 'Promotion',
    icon: <Coupon />,
    maxChildren: 1,
    parentType: 'promotion',
    nodeAttribute: true,
    bgColor: CJ_NODE_COLOR_PALETTE.channels,
    description: 'Promotion',
    childrenNodes: [cloneDeep(VIRTUAL_NODE)],
    value: { promotionId: null, wait_time: null, time_format: null, dateTime: null },
  },
  coupon: {
    nodeType: 'coupon',
    parentType: 'coupon',
    name: 'Coupon',
    icon: <Coupon />,
    maxChildren: 1,
    parentType: 'promotion',
    nodeAttribute: true,
    bgColor: CJ_NODE_COLOR_PALETTE.channels,
    description: 'Coupon',
    childrenNodes: [cloneDeep(VIRTUAL_NODE)],
    value: {},
  },
  outOfPromotion: {
    nodeType: 'outOfPromotion',
    parentType: 'outOfPromotion',
    name: 'Out of promotion',
    icon: <Coupon />,
    maxChildren: 1,
    parentType: 'promotion',
    nodeAttribute: true,
    bgColor: CJ_NODE_COLOR_PALETTE.channels,
    description: 'Out of promotion',
    childrenNodes: [cloneDeep(VIRTUAL_NODE)],
    value: { promotionId: null },
  },
  split: {
    nodeType: 'split',
    parentType: 'split',
    name: 'A/B Testing',
    icon: <CallSplit transform={`rotate(-180)`} />,
    maxChildren: 2,
    nodeAttribute: true,
    description: 'Split into two different directions A and B.',
    childrenNodes: [
      { ...VIRTUAL_NODE, nodeAttribute: false },
      { ...VIRTUAL_NODE, index: 1, nodeAttribute: true },
    ],
    value: {},
  },
  checkReachability: {
    nodeType: 'checkReachability',
    name: 'Check Reachability',
    icon: <CurrentLocation />,
    maxChildren: 2,
    parentType: 'condition',
    bgColor: CJ_NODE_COLOR_PALETTE.conditions,
    description: 'Check if customers are reachable on a specific channel',
    nodeAttribute: true,
    childrenNodes: [
      { ...VIRTUAL_NODE, nodeAttribute: false },
      { ...VIRTUAL_NODE, index: 1, nodeAttribute: true },
    ],
    value: {},
  },
  checkUserAttr: {
    nodeType: 'checkUserAttr',
    name: 'Check attribute',
    icon: <UserCheck />,
    maxChildren: 2,
    parentType: 'condition',
    nodeAttribute: true,
    bgColor: CJ_NODE_COLOR_PALETTE.conditions,
    childrenNodes: [
      { ...VIRTUAL_NODE, nodeAttribute: false },
      { ...VIRTUAL_NODE, index: 1, nodeAttribute: true },
    ],
    description: 'Check if customers meet certain conditions',
    value: {},
  },
  checkAudienceSegment: {
    nodeType: 'checkAudienceSegment',
    name: 'Audience',
    icon: <User />,
    maxChildren: 1,
    parentType: 'condition',
    nodeAttribute: true,
    childrenNodes: [cloneDeep(VIRTUAL_NODE)],
    bgColor: CJ_NODE_COLOR_PALETTE.audience,
    description: 'Target to a specific audience segment or all segments',
    value: {
      audience_segment_id: null,
    },
    alias: 2,
  },
  web_push: {
    nodeType: 'web_push',
    name: 'Web push',
    icon: <WebAsset />,
    maxChildren: 1,
    parentType: 'channel',
    nodeAttribute: true,
    bgColor: CJ_NODE_COLOR_PALETTE.channel,
    description: 'Send web push notification to customers',
    childrenNodes: [cloneDeep(VIRTUAL_NODE)],
    value: {},
  },
  harasocial: {
    nodeType: 'harasocial',
    name: 'Harasocial',
    icon: <WebAsset />,
    maxChildren: 1,
    parentType: 'channel',
    nodeAttribute: true,
    bgColor: CJ_NODE_COLOR_PALETTE.channel,
    description: 'Send message on messenger',
    childrenNodes: [cloneDeep(VIRTUAL_NODE)],
    value: {},
  },
  hrs_store_farming: {
    nodeType: 'hrs_store_farming',
    name: 'Harasocial - Store farming',
    icon: <WebAsset />,
    maxChildren: 1,
    parentType: 'channel',
    nodeAttribute: true,
    bgColor: CJ_NODE_COLOR_PALETTE.channel,
    description: 'Assign task to employee',
    childrenNodes: [cloneDeep(VIRTUAL_NODE)],
    value: {},
  },
  kaipass: {
    nodeType: 'kaipass',
    name: 'Kaipass Notification',
    icon: <WebAsset />,
    maxChildren: 1,
    parentType: 'channel',
    nodeAttribute: true,
    bgColor: CJ_NODE_COLOR_PALETTE.channel,
    description: 'Send Kaipass notification to customers',
    childrenNodes: [cloneDeep(VIRTUAL_NODE)],
    value: {},
  },
  sms: {
    nodeType: 'sms',
    name: 'SMS',
    icon: <Textsms />,
    maxChildren: 1,
    parentType: 'channel',
    nodeAttribute: true,
    childrenNodes: [cloneDeep(VIRTUAL_NODE)],
    bgColor: CJ_NODE_COLOR_PALETTE.channel,
    description: 'Send SMS notification to customers',
    value: {},
  },
  email: {
    nodeType: 'email',
    name: 'E-mail',
    icon: <EmailOutline />,
    maxChildren: 1,
    parentType: 'channel',
    nodeAttribute: true,
    description: 'Send e-mail to customers',
    childrenNodes: [cloneDeep(VIRTUAL_NODE)],
    bgColor: CJ_NODE_COLOR_PALETTE.channel,
    value: {},
  },
  popup: {
    // #Spin game
    // key: 'popup',
    // name: 'Popup',
    // icon: <Popup />,
    // data: [...POPUPINIT],

    // #Popup dafault
    nodeType: 'popup',
    name: 'Popup',
    icon: <Popup />,
    maxChildren: 1,
    parentType: 'channel',
    nodeAttribute: true,
    description: 'Popup window to customers',
    childrenNodes: [cloneDeep(VIRTUAL_NODE)],
    bgColor: CJ_NODE_COLOR_PALETTE.channel,
    value: {},
  },
  ol_app: {
    nodeType: 'ol_app',
    name: 'App Push',
    icon: <Notification />,
    maxChildren: 1,
    parentType: 'channel',
    nodeAttribute: true,
    description: 'Popup window to customers',
    childrenNodes: [cloneDeep(VIRTUAL_NODE)],
    bgColor: CJ_NODE_COLOR_PALETTE.channel,
    value: {},
  },
  appPopup: {
    nodeType: 'appPopup',
    name: 'App - Popup',
    icon: <Popup />,
    maxChildren: 1,
    parentType: 'channel',
    nodeAttribute: true,
    description: 'Popup window to customers',
    childrenNodes: [cloneDeep(VIRTUAL_NODE)],
    bgColor: CJ_NODE_COLOR_PALETTE.channel,
    value: {},
  },
  webPopup: {
    nodeType: 'webPopup',
    name: 'Web - Popup',
    icon: <Popup />,
    maxChildren: 1,
    parentType: 'channel',
    nodeAttribute: true,
    description: 'Popup window to customers',
    childrenNodes: [cloneDeep(VIRTUAL_NODE)],
    bgColor: CJ_NODE_COLOR_PALETTE.channel,
    value: {},
  },
  appInPage: {
    nodeType: 'appInPage',
    name: 'App - In-page section',
    icon: <Popup />,
    maxChildren: 1,
    parentType: 'channel',
    nodeAttribute: true,
    description: 'Popup window to customers',
    childrenNodes: [cloneDeep(VIRTUAL_NODE)],
    bgColor: CJ_NODE_COLOR_PALETTE.channel,
    value: {},
  },
  webInPage: {
    nodeType: 'webInPage',
    name: 'Web - In-page section',
    icon: <Popup />,
    maxChildren: 1,
    parentType: 'channel',
    nodeAttribute: true,
    description: 'Popup window to customers',
    childrenNodes: [cloneDeep(VIRTUAL_NODE)],
    bgColor: CJ_NODE_COLOR_PALETTE.channel,
    value: {},
  },
  znsByTemplateId: {
    nodeType: 'znsByTemplateId',
    name: 'ZNS By Template Id',
    icon: <Notification />,
    maxChildren: 1,
    parentType: 'channel',
    nodeAttribute: true,
    description: 'Send ZNS',
    childrenNodes: [cloneDeep(VIRTUAL_NODE)],
    bgColor: CJ_NODE_COLOR_PALETTE.channel,
    value: {},
  },
  aiCheck: {
    nodeType: 'aiCheck',
    name: 'AI suggestion',
    icon: <NumberOutlined />,
    maxChildren: 1,
    parentType: 'aiCheck',
    nodeAttribute: true,
    description: 'AI Check',
    childrenNodes: [cloneDeep(VIRTUAL_NODE)],
    bgColor: '#EB2F96',
    value: {},
  },
}

export const DEFAULT_NODE = {
  ...cloneDeep(NODE_TYPES.entrance),
  childrenNodes: [
    {
      ...cloneDeep(NODE_TYPES.checkAudienceSegment),
      // childrenNodes: [
      //   // cloneDeep(NODE_TYPES.virtual),
      //   //   { ...cloneDeep(NODE_TYPES.checkReachability), childrenNodes: [cloneDeep(NODE_TYPES.wait)] },
      //   //   cloneDeep(NODE_TYPES.virtual),
      // ],
    },
  ],
}

export const CHANNELS = [
  { ...NODE_TYPES['web_push'], data: 'web_push' },
  { ...NODE_TYPES['sms'], data: 'sms' },
  { ...NODE_TYPES['email'], data: 'email' },
  { ...NODE_TYPES['popup'], data: 'popup' },
  { ...NODE_TYPES['appPopup'], data: 'appPopup' },
  { ...NODE_TYPES['webPopup'], data: 'webPopup' },
  { ...NODE_TYPES['appInPage'], data: 'appInPage' },
  { ...NODE_TYPES['webInPage'], data: 'webInPage' },
  { ...NODE_TYPES['znsByTemplateId'], data: 'znsByTemplateId' },
  // { ...NODE_TYPES['kaipass'], data: 'kaipass' },
  // { ...NODE_TYPES['harasocial'], data: 'harasocial' },
  // { ...NODE_TYPES['hrs_store_farming'], data: 'hrs_store_farming' },
  { ...NODE_TYPES['ol_app'], data: 'ol_app' },
]

export const CONDITIONS = [
  {
    ...NODE_TYPES['checkReachability'],
    data: 'checkReachability',
  },
  {
    ...NODE_TYPES['checkUserAttr'],
    data: 'checkUserAttr',
  },
]

export const PROMOTIONS = [
  {
    ...NODE_TYPES['promotion'],
    data: 'promotion',
  },
  {
    ...NODE_TYPES['coupon'],
    data: 'coupon',
  },
  {
    ...NODE_TYPES['outOfPromotion'],
    data: 'outOfPromotion',
  },
]

export const INIT = [
  {
    key: 'channels',
    name: 'Channels',
    icon: CHANNELS[0].icon,
    data: CHANNELS,
  },
  {
    key: 'conditions',
    name: 'Conditions',
    icon: CONDITIONS[0].icon,
    data: CONDITIONS,
  },
  {
    ...NODE_TYPES['wait'],
    data: 'wait',
  },
  {
    ...NODE_TYPES['waitIn'],
    data: 'waitIn',
  },
  {
    ...NODE_TYPES['split'],
    data: 'split',
  },
  {
    ...NODE_TYPES['promotion'],
    data: 'promotion',
    icon: <Coupon />,
    data: PROMOTIONS,
  },
  {
    ...NODE_TYPES['aiCheck'],
    data: 'aiCheck',
    icon: <NumberOutlined />,
    data: 'aiCheck',
  },
]
