import React from 'react'
import { Avatar } from 'antd'
import { capitalizeFirstLetter, generateColor } from 'Utils'
import './index.scss'
import PieChart from 'Components/Charts/PieChart'

// const filteredInfo = pickBy(userProfileSchema, identity)

interface userInfo {
  [key: string]: string | number | null
}

interface PurchasedCategoryInterface {
  data: string
}

const PurchasedCategory = ({ data }: PurchasedCategoryInterface) => {
  return (
    <div onClick={(e) => e.stopPropagation()} className="PurchasedCategoryComponent">
      <PieChart showLegend={false} data={data} isVertical={true} />
      {/* <BarChart data={data} type="horizontalBar" isStacked={false} chartId={false} /> */}
    </div>
  )
}

export default PurchasedCategory
